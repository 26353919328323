import React from "react";
console.log("App is rendering");

const Home = () => {
    return (
        <div>
            <h1>Welcome to Florida Gateway Auction</h1>
        </div>
    );
};

export default Home;
